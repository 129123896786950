<script>
import Logo from '@components/Logo';
import { getDynamicIeBySubDomain } from '@commons/helpers';

export default {
	name: 'Login',
	components: {
		Logo,
	},
	data() {
		return {
			ie: ''
		}
	},
	mounted() {
		const iesList = require('../../ies/ies.list.json');
		const ie      = getDynamicIeBySubDomain(window.location.hostname, iesList);

		this.ie = ie
	}
}
</script>

<template>
	<div
		class="page login"
		:class="`${ie}`"
	>
		<header class="header">
			<Logo maxwidth="235px" />
		</header>

		<div class="section">
			<router-view
				:key="$route.path"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@styles/_variables";

::v-deep.login h2.title {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 1.4rem;
}

.page.login {
	background-color: $gray-20;
	background-color: var(--gray-20);
}

.header {
	height: 116px;
	border-radius: 0px 0px 20px 20px;
	background-color: $white;
	background-color: var(--white);
	// border-top: 6px solid $light-green;
	// border-top: 6px solid mix($primary, $white, 50%);
	// border-top: 6px solid var(--light-green);
	display: flex;
	justify-content: center;
	align-items: center;
}

</style>
